import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import { Link } from 'gatsby';
import { ThemeContext } from '../utils';

const Hero = () => {
  const [theme] = useContext(ThemeContext);

  const data = useStaticQuery(graphql`
    query HeroQuery {
      hero: file(absolutePath: { regex: "/hero.jpg/" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <div className={`duration-300 transition-colors relative ${theme.background} overflow-hidden`}>
      <div className="max-w-screen-xl mx-auto ">
        <div className={`duration-300 transition-colors relative z-10 ${theme.background} lg:max-w-2xl lg:w-full`}>
          <svg
            className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-teal-500 transform translate-x-1/2"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
          >
            <polygon points="50,0 80,0 50,100 20,100" />
          </svg>

          <div className="py-20 mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
            <div className="text-center lg:text-left">
              <h2
                className={`duration-300 transition-colors text-4xl tracking-tight leading-10 font-extrabold ${theme.textStrong} sm:text-5xl sm:leading-none`}
              >
                <span className="text-teal-600">
                  APT<span className={theme.textExtraLight}>593</span>
                </span>
              </h2>
              <p
                className={`duration-300 transition-colors text-left mt-3 text-base ${theme.text} sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0`}
              >
                La vida nos ha enseñado que en medio de un CTF, no todos los miembros del equipo deben tomar breaks a la
                vez. <span className="text-teal-500">#AdiosCanada</span>
              </p>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                <div className="rounded-md shadow">
                  <Link
                    to="/blog/"
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-teal-600 hover:bg-teal-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10 no-underline"
                  >
                    Leer entradas
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden md:block md:absolute md:inset-y-0 md:right-0 md:w-1/2">
        <div className="w-full object-cover md:w-full md:h-full">
          <Image className="h-full" fluid={data.hero.childImageSharp.fluid} alt={'hero'} />
        </div>
      </div>
    </div>
  );
};

export default Hero;
